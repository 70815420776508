"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var getMobileFontSize = function getMobileFontSize(fontSize) {
  if (typeof fontSize !== 'number') return undefined;

  switch (true) {
    case fontSize >= 101:
      return fontSize * 0.55;

    case fontSize >= 60 && fontSize < 101:
      return fontSize * 0.6;

    case fontSize >= 36 && fontSize < 60:
      return fontSize * 0.7;

    case fontSize >= 25 && fontSize < 36:
      return fontSize * 0.8;

    case fontSize >= 16 && fontSize < 25:
      return fontSize * 0.9;

    default:
      return fontSize * 1;
  }
};

var _default = getMobileFontSize;
exports["default"] = _default;