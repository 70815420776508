"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buttonStyle = exports.descriptionStyle = exports.headerStyle = exports.overlayBackground = exports.root = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _color = _interopRequireDefault(require("color"));

var _media = require("../../constants/media");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var root = function root(width, mobileWidth, fontFamily) {
  return (0, _defineProperty2["default"])({
    width: "".concat(width, "%"),
    fontFamily: fontFamily
  }, _media.PHONE_MEDIA, {
    width: "".concat(mobileWidth, "%")
  });
};

exports.root = root;

var overlayBackground = function overlayBackground(_ref2) {
  var showOverlay = _ref2.showOverlay,
      overlayBackgroundColor = _ref2.overlayBackgroundColor,
      position = _ref2.position;
  var defaultStyle = {
    background: showOverlay && overlayBackgroundColor ? (0, _color["default"])(overlayBackgroundColor).alpha(0.5) : ''
  };

  switch (position) {
    case 'topleft':
      return _objectSpread(_objectSpread({}, defaultStyle), {}, {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        textAlign: 'left'
      });

    case 'topMiddle':
      return _objectSpread(_objectSpread({}, defaultStyle), {}, {
        alignItems: 'flex-start',
        justifyContent: 'center',
        textAlign: 'center'
      });

    case 'topright':
      return _objectSpread(_objectSpread({}, defaultStyle), {}, {
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        textAlign: 'right'
      });

    case 'middleleft':
      return _objectSpread(_objectSpread({}, defaultStyle), {}, {
        alignItems: 'center',
        justifyContent: 'flex-start',
        textAlign: 'left'
      });

    case 'middle':
      return _objectSpread(_objectSpread({}, defaultStyle), {}, {
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
      });

    case 'middleright':
      return _objectSpread(_objectSpread({}, defaultStyle), {}, {
        alignItems: 'center',
        justifyContent: 'flex-end',
        textAlign: 'right'
      });

    case 'bottomleft':
      return _objectSpread(_objectSpread({}, defaultStyle), {}, {
        alignItems: 'flex-end',
        justifyContent: 'flex-start',
        textAlign: 'left'
      });

    case 'bottomMiddle':
      return _objectSpread(_objectSpread({}, defaultStyle), {}, {
        alignItems: 'flex-end',
        justifyContent: 'center',
        textAlign: 'center'
      });

    case 'bottomright':
      return _objectSpread(_objectSpread({}, defaultStyle), {}, {
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        textAlign: 'right'
      });

    default:
      return _objectSpread(_objectSpread({}, defaultStyle), {}, {
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        textAlign: 'left'
      });
  }
};

exports.overlayBackground = overlayBackground;

var headerStyle = function headerStyle(fontSize, mobileFontSize, bold) {
  return (0, _defineProperty2["default"])({
    fontSize: "".concat(fontSize, "px"),
    fontWeight: bold ? '600' : 'normal'
  }, _media.PHONE_MEDIA, {
    fontSize: "".concat(mobileFontSize, "px")
  });
};

exports.headerStyle = headerStyle;

var descriptionStyle = function descriptionStyle(fontSize, mobileFontSize) {
  return (0, _defineProperty2["default"])({
    fontSize: "".concat(fontSize, "px")
  }, _media.PHONE_MEDIA, {
    fontSize: "".concat(mobileFontSize, "px")
  });
};

exports.descriptionStyle = descriptionStyle;

var buttonStyle = function buttonStyle(fontSize, mobileFontSize, color, buttonHoverColor) {
  return (0, _defineProperty2["default"])({
    fontSize: "".concat(fontSize, "px"),
    border: "1px solid ".concat(color),
    ':hover': {
      background: color,
      color: buttonHoverColor
    }
  }, _media.PHONE_MEDIA, {
    fontSize: "".concat(mobileFontSize, "px")
  });
};

exports.buttonStyle = buttonStyle;